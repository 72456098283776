//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { handleTaskEnd } from '@/api/modular/flowable/handleTaskManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      spinningLoading: false,
      recordData: {},
      form: this.$form.createForm(this)
    }
  },
  methods: {
    /**
     * 初始化方法
     */
    open (record) {
      this.recordData = record
      this.visible = true
    },
    /**
     * 提交
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          values.processDefinitionId = this.recordData.procIns.id
          values.taskId = this.recordData.id
          handleTaskEnd(values).then((res) => {
            if (res.success) {
              this.$message.success('終止成功')
              this.returnPage()
            } else {
              this.$message.error('終止失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    /**
     * 返回並清空界面緩存
     */
    returnPage () {
      this.visible = false
      this.form.resetFields()
      this.$emit('close')
    },
    /**
     * 關閉
     */
    handleCancel () {
      this.visible = false
    }
  }
}
